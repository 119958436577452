<template>
  <div>
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: TYPE -->
        <b-col cols="12" md="4">
          <b-form-group label="Type Category" label-for="user-status">
            <v-select
              v-model="model.typeId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="categoryTypeOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="user-status"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Username -->
        <b-col cols="12" md="4">
          <b-form-group label="Name Category" label-for="nameCountry">
            <b-form-input id="username" v-model="model.name" />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col cols="12" md="4">
          <b-form-group label="Status" label-for="user-status">
            <v-select
              v-model="model.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="user-status"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="createCountry()"
    >
      Create Category
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="reset"
    >
      Reset
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import router from "@/router";
import store from "@/store";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const toast = useToast();

    const statusOptions = [
      { label: "Active", value: true },
      { label: "Inactive", value: false },
    ];

    const categoryTypeOptions = ref([]);

    const parser = JSON.parse(localStorage.getItem("userData"));
    const idOffice = parser.dataUser.officeEmployee_officeId;
    const idRol = parser.userData.rolId;

    const model = ref({
      name: "",
      typeId: "",
      status: true,
      idRol,
      idOffice,
    });
    const userData = ref(null);

    const type = [];
    store
      .dispatch("app-user/fetchHelpers")
      .then((response) => {
        const { categoryType } = response.data.data;

        categoryType.map((data, item) => {
          type.push({ label: data.name, value: data.id, id: item });
        });

        categoryTypeOptions.value = type;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    const createCountry = () => {
      store
        .dispatch("app-user/addCountry", { model })
        .then((response) => {
          userData.value = response.data;
          toast({
            component: ToastificationContent,
            props: {
              title: `The category ${model.value.name} was created correctly`,
              icon: "AlertTriangleIcon",
              variant: "success",
            },
          });
          router.push({ name: "apps-category-list" });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            userData.value = undefined;
          }
        });
    };

    const reset = () => {
      model.value.name = "";
      model.value.status = "";
    };

    return {
      avatarText,
      statusOptions,
      model,

      categoryTypeOptions,
      createCountry,
      reset,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
